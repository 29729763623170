import React, { useState } from 'react';
import './Resumepost.css';

const Resumepost = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contact: '',
    functionalArea: '',
    resume: null,
    fees: '',
    amount: '',
  });

  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { id, value, files } = e.target;
    setFormData({
      ...formData,
      [id]: files ? files[0] : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName.trim()) newErrors.fullName = 'Full Name is required.';
    if (!formData.email.trim()) newErrors.email = 'Email is required.';
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email))
      newErrors.email = 'Invalid email address.';
    if (!formData.contact.trim()) newErrors.contact = 'Contact number is required.';
    else if (!/^\d{10}$/.test(formData.contact))
      newErrors.contact = 'Contact number must be 10 digits.';
    if (!formData.functionalArea.trim()) newErrors.functionalArea = 'Functional Area is required.';
    if (!formData.resume) newErrors.resume = 'Please upload your resume.';
    if (!formData.fees) newErrors.fees = 'Please select a fee option.';
    if (!formData.amount.trim()) newErrors.amount = 'Amount is required.';
    else if (isNaN(formData.amount)) newErrors.amount = 'Amount must be a number.';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    setFormSubmitted(true);
    alert('Data submitted successfully.');
  };

  return (
    <div className="reg2-container">
      {/* Image Section */}
      <div className="reg2-img-section">
        <img
          src="https://images.pexels.com/photos/590016/pexels-photo-590016.jpeg?auto=compress&cs=tinysrgb&w=600"
          alt="Post Resume"
          className="reg2-img"
        />
        <div className="reg2-overlay">
          <h2 className="reg2-text-overlay">Post Resume</h2>
        </div>
      </div>

      {/* Form Section */}
      <div className="reg2-form-section">
        <h3 className="reg2-form-heading">Register with us</h3>
        <form className="reg2-form" onSubmit={handleSubmit}>
          <div className="reg2-row">
            <div className="reg2-form-group">
              <label htmlFor="fullName" className="reg2-label">Full Name</label>
              <input
                type="text"
                id="fullName"
                className="reg2-input"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="Enter your full name"
              />
              {errors.fullName && <span className="error">{errors.fullName}</span>}
            </div>
            <div className="reg2-form-group">
              <label htmlFor="email" className="reg2-label">Email</label>
              <input
                type="email"
                id="email"
                className="reg2-input"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
          </div>
          <div className="reg2-row">
            <div className="reg2-form-group">
              <label htmlFor="contact" className="reg2-label">Contact No</label>
              <input
                type="text"
                id="contact"
                className="reg2-input"
                value={formData.contact}
                onChange={handleChange}
                placeholder="Enter your contact number"
              />
              {errors.contact && <span className="error">{errors.contact}</span>}
            </div>
            <div className="reg2-form-group">
              <label htmlFor="functionalArea" className="reg2-label">Functional Area</label>
              <input
                type="text"
                id="functionalArea"
                className="reg2-input"
                value={formData.functionalArea}
                onChange={handleChange}
                placeholder="Enter your functional area"
              />
              {errors.functionalArea && <span className="error">{errors.functionalArea}</span>}
            </div>
          </div>
          <div className="reg2-row">
            <div className="reg2-form-group">
              <label htmlFor="resume" className="reg2-label">Upload Resume</label>
              <input
                type="file"
                id="resume"
                className="reg2-input"
                onChange={handleChange}
              />
              {errors.resume && <span className="error">{errors.resume}</span>}
            </div>
            <div className="reg2-form-group">
              <label htmlFor="fees" className="reg2-label">Fees For</label>
              <select
                id="fees"
                className="reg2-select"
                value={formData.fees}
                onChange={handleChange}
              >
                <option value="">Select an option</option>
                <option value="option1">Training</option>
                <option value="option2">Placement</option>
              </select>
              {errors.fees && <span className="error">{errors.fees}</span>}
            </div>
            <div className="reg2-form-group">
              <label htmlFor="amount" className="reg2-label">Amount</label>
              <input
                type="text"
                id="amount"
                className="reg2-input"
                value={formData.amount}
                onChange={handleChange}
                placeholder="Enter amount"
              />
              {errors.amount && <span className="error">{errors.amount}</span>}
            </div>
          </div>
          <div className="reg2-form-group">
            <button type="submit" className="reg2-submit-btn">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Resumepost;
