// import React from "react";
// import "./Services.css";

// const Services = () => {
//   return (
//     <div className="services-container-srv2">
//       {/* Heading Section */}
//       <h1 className="services-heading-srv2">Our Services</h1>

//       {/* Services Row */}
//       <div className="services-row-srv2">
//         {/* Box 1 */}
//         <div className="services-box-srv2">
//           <div className="services-box-header-srv2">
//             <img
//               src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSm5K96ccqfCfEnlhYkBndQZQPjC4Ezq7w-EceeOW0RAfR_0lpV"
//               alt="Training and Development"
//               className="services-logo-srv2"
//             />
//             <h2 className="services-subheading-srv2">Training & Development</h2>
//           </div>
//           <p className="services-description-srv2">
//             The objective of Training Program is to provide a comprehensive
//             learning platform to students wherein they can enhance their
//             employability skills.
//           </p>
//         </div>

//         {/* Box 2 */}
//         <div className="services-box-srv2">
//           <div className="services-box-header-srv2">
//             <img
//               src="https://pbs.twimg.com/profile_images/1235943430519435264/fgg5R6sI_400x400.png"
//               alt="Corporate Training"
//               className="services-logo-srv2"
//             />
//             <h2 className="services-subheading-srv2">Corporate Training</h2>
//           </div>
//           <p className="services-description-srv2">
//             Our corporate training provides you the flexibility to customize
//             training as per your firm’s need.
//           </p>
//         </div>

//         {/* Box 3 */}
//         <div className="services-box-srv2">
//           <div className="services-box-header-srv2">
//             <img
//               src="https://cdn-icons-png.flaticon.com/512/3135/3135789.png"
//               alt="College Campus"
//               className="services-logo-srv2"
//             />
//             <h2 className="services-subheading-srv2">College Campus</h2>
//           </div>
//           <p className="services-description-srv2">
//             Hiring Process Streamlined in Advance, Candidates walking through
//             Newspaper, College/Institutes, Social Media.
//           </p>
//         </div>
//       </div>

//       {/* New Section */}
//       <div className="new-section-srv2">
//         {/* Left Side */}
//         <div className="recruitment-card-srv2">
//           <div className="services-box-header-srv2">
//             <img
//               src="https://cdn-icons-png.flaticon.com/512/3062/3062634.png"
//               alt="Recruitment Process"
//               className="services-logo-srv2"
//             />
//             <h2 className="services-subheading-srv2">Recruitment Process</h2>
//           </div>
//           <p className="services-description-srv2">
//             We are engaged in offering high-standard Human Resource Services to
//             our clients.
//           </p>
//         </div>

//         {/* Right Side */}
//         <div className="recruitment-stats-srv2">
//   <h2 className="recruitment-heading-srv2">Our Recruitment Ratio Till Date</h2>
//   <div className="recruitment-ratios-srv2">
//     <div className="ratio-container-srv2">
//       <div className="ratio-bar-srv2 ratio-20">IT</div>
//     </div>
//     <div className="ratio-container-srv2">
//       <div className="ratio-bar-srv2 ratio-40">MANUFACTURING</div>
//     </div>
//     <div className="ratio-container-srv2">
//       <div className="ratio-bar-srv2 ratio-60">BPO</div>
//     </div>
//     <div className="ratio-container-srv2">
//       <div className="ratio-bar-srv2 ratio-80">KPO</div>
//     </div>
//     <div className="ratio-container-srv2">
//       <div className="ratio-bar-srv2 ratio-100">FMCG & CD</div>
//     </div>
//   </div>
// </div>

//       </div>
//     </div>
//   );
// };

// export default Services;

// // csssssssssssss



// /* General Container */
// .services-container-srv2 {
//   width: 100%;
//   padding: 20px;
//   box-sizing: border-box;
// }

// /* Heading */
// .services-heading-srv2 {
//   text-align: center;
//   font-size: 2.5rem;
//   font-weight: bold;
//   margin-bottom: 20px;
//   color: #333;
// }

// /* Services Row */
// .services-row-srv2 {
//   display: flex;
//   justify-content: space-between;
//   gap: 20px;
//   flex-wrap: wrap;
// }

// /* Individual Service Box */
// .services-box-srv2 {
//   flex: 1;
//   min-width: 280px;
//   max-width: 33%;
//   border: 1px solid #ddd;
//   padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   background-color: #fff;
//   transition: transform 0.3s ease, box-shadow 0.3s ease;
//   cursor: pointer;
// }

// .services-box-srv2:hover {
//   transform: translateY(-5px);
//   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
// }

// /* Box Header */
// .services-box-header-srv2 {
//   display: flex;
//   align-items: center;
//   margin-bottom: 15px;
// }

// /* Service Logo */
// .services-logo-srv2 {
//   width: 50px;
//   height: 50px;
//   margin-right: 15px;
// }

// /* Subheading */
// .services-subheading-srv2 {
//   font-size: 1.5rem;
//   font-weight: bold;
//   color: #555;
// }

// /* Description */
// .services-description-srv2 {
//   font-size: 1rem;
//   color: #666;
//   line-height: 1.5;
// }

// /* Recruitment Section */
// .new-section-srv2 {
//   display: flex;
//   gap: 20px;
//   margin-top: 40px;
//   flex-wrap: wrap;
// }

// /* Recruitment Card */
// .recruitment-card-srv2 {
//   flex: 1;
//   min-width: 300px;
//   border: 1px solid #ddd;
//   padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   background-color: #fff;
// }
// /* Recruitment Stats */
// .recruitment-stats-srv2 {
//   flex: 2;
//   max-width: 66.66%;
//   margin-top: 20px;
// }

// .recruitment-heading-srv2 {
//   font-size: 1.8rem;
//   font-weight: bold;
//   color: #555;
//   margin-bottom: 20px;
//   text-align: center;
// }

// /* Ratio Container */
// .ratio-container-srv2 {
//   position: relative;
//   height: 40px;
//   border-radius: 5px;
//   background-color: #f0f0f0;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   margin-bottom: 10px;
//   overflow: hidden;
// }

// /* Ratio Bars */
// .ratio-bar-srv2 {
//   height: 100%;
//   border-radius: 5px 0 0 5px;
//   color: white;
//   font-size: 1rem;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   font-weight: bold;
//   padding-left: 10px;
//   transition: width 0.5s ease;
// }

// /* Individual Ratios */
// .ratio-20 {
//   background-color: #0f3bab;
//   width: 80%;
// }

// .ratio-40 {
//   background-color: #0b642c;
//   width: 55%;
// }

// .ratio-60 {
//   background-color: #ff6666;
//   width: 60%;
// }

// .ratio-80 {
//   background-color: #4a0657;
//   width: 80%;
// }

// .ratio-100 {
//   background-color: #ffe415;
//   width: 100%;
// }

// /* Responsive Design */
// @media (max-width: 768px) {
//   .recruitment-stats-srv2 {
//     max-width: 100%;
//   }

//   .recruitment-heading-srv2 {
//     font-size: 1.5rem;
//   }

//   .ratio-bar-srv2 {
//     font-size: 0.9rem;
//   }
// }
























// nedwewwwwwwww








import React from 'react';
import './Services.css';

const servicesData = [
  {
    title: 'Training and Development',
    description:
      'The objective of Training Program is to provide a comprehensive learning platform to students wherein they can enhance their employability skills.',
    icon: '✈️', // Replace with an appropriate icon/image
  },
  {
    title: 'Corporate Training',
    description:
      'Our corporate training provides you the flexibility to customized training as per your firm’s need.',
    icon: '📚', // Replace with an appropriate icon/image
  },
  {
    title: 'College Campus',
    description:
      'Hiring Process Streamlined in Advance, Candidates walking through Newspaper, College/Institutes, Social Media.',
    icon: '🔥', // Replace with an appropriate icon/image
  },
  {
    title: 'Recruitment Process',
    description:
      'We are engaged in offering high-standard Human Resource Services to our clients.',
    icon: '⚙️', // Replace with an appropriate icon/image
  },
];

const ratiosData = [
  { label: 'IT', percentage: 70, color: '#1E88E5' },
  { label: 'Manufacturing', percentage: 65, color: '#43A047' },
  { label: 'BPO', percentage: 50, color: '#03A9F4' },
  { label: 'KPO', percentage: 60, color: '#FFEB3B' },
  { label: 'FMCG & CD', percentage: 40, color: '#E53935' },
];

const Services = () => {
  return (
    <div className="services-container">
       <h1 className="services-heading-srv2">Our Services</h1>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
      <div className="ratios-section">
        <h2>Our recruitment ratio till time</h2>
        {ratiosData.map((ratio, index) => (
          <div key={index} className="ratio-bar">
            <span className="ratio-label">{ratio.label}</span>
            <div
              className="ratio-fill"
              style={{

                width: `${ratio.percentage}%`,
                backgroundColor: ratio.color,
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
