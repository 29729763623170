import React from 'react';
import './Corporate.css';

const Corporate = () => {
  return (
    <div className="corporate-container-crt1">
      {/* Image Section */}
      <div className="image-section-crt1">
        <img
          src="https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="Corporate Banner"
          className="corporate-image-crt1"
        />
        <h1 className="corporate-heading-crt1">Corporate Profile</h1>
      </div>

      {/* Text Section 1 */}
      <div className="text-section-crt1">
        <p>
          <strong>Teamstaff outsourcing</strong> is a professional recruitment firm which strongly believes that quality is a process, not an end result. 
          Therefore, we have selected highly professional recruiters with strong technical backgrounds. Most of our recruiters are qualified Engineers, Science 
          Graduates, and MBAs. They are with Teamstaff outsourcing not only because of their experience and expertise but also because of their desire to 
          excel in the field of proper deployment of resources. We are well aware that a consultant develops a link between a candidate and the client organization. 
          Thus, each of our handpicked consultants is a highly experienced professional in the sector/technology they serve.
        </p>
      </div>

      {/* Text Section 2 */}
      <div className="text-section-crt1">
        <p>
          Our current team comprises qualified professionals combining experience in multiple disciplines including Human Resources, Technology, Sales, and 
          Marketing. A strong service attitude coupled with a desire to excel and innovate is an integral part of our team members. Our team consists of highly 
          ambitious and confident recruiters.
        </p>
      </div>

      {/* Text Section 3 */}
      <div className="text-section-crt1">
        <p>
          It is because of their ability to attract the best talent that <strong>Teamstaff outsourcing</strong> today is one of the leading recruitment firms 
          in Pune and works with leading companies in IT, ITES, Insurance, Finance, Power, Steel, Infrastructure, Pharmaceuticals, FMCG, MNC, and other sectors.
        </p>
      </div>

      {/* Recruitment Overview Section */}
      <div className="recruitment-overview-section-crt1">
        <h2 className="recruitment-heading-crt1">
          <span className="recruitment-text1-crt1">Recruitment</span> 
          <span className="recruitment-text2-crt1">Overview</span>
        </h2>
        <p>
          “Teamstaff outsourcing” operates its corporate affairs from the city of Pune, Maharashtra with a nation-wide network of consultants to meet all HR 
          requirements including:
        </p>
        {/* Five Columns Row */}
        <div className="five-columns-crt1">
          <h3 className="column-heading-crt1">HR Consulting</h3>
          <h3 className="column-heading-crt2">IT Staffing</h3>
          <h3 className="column-heading-crt3">Executive Search</h3>
          <h3 className="column-heading-crt4">Project Hiring</h3>
          <h3 className="column-heading-crt5">Training & Development</h3>
        </div>

        {/* Additional Info Section */}
        <p className="bold-text-crt1">
          For manpower selection & placement, we have a very sizable & well-monitored comprehensive updated databank of high-caliber professionals. 
          We provide systematic and time-bound recruitment assurance to cater to the needs of our Clientele. As we have grown from the grass-root 
          level and learned over time about the volatile business environment, we believe we can provide the best manpower available in the market.
        </p>
        <p>
          We give importance to long-term relationships, faster turnaround times, while carving a niche of ethics & client confidence. We specialize 
          in providing quality services to our rich and expanding client base and command the privilege of a well-qualified candidature bank consisting 
          of smart, young, energetic specialists, executives, and senior executives who rely on us for their bright future career prospects.
        </p>
      </div>
    </div>
  );
};

export default Corporate;
