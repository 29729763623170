import React from 'react';
import './VisionM.css';

const VisionM = () => {
  return (
    <div className="VisionM-container-VM1">
      {/* Image Banner Section */}
      <div className="image-section-VM1">
        <img 
          src="https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" 
          alt="VisionM Banner" 
          className="VisionM-image-VM1"
        />
        <h1 className="VisionM-heading-VM1">Vision and Mission</h1>
      </div>

      {/* Header Section */}
      <div className="VisionM-header-VM1">
        <h1 className="VisionM-main-heading-VM1">Vision and Mission</h1>
      </div>

      {/* Vision and Mission Section */}
      <div className="VisionM-content-VM1">
        {/* Vision Box */}
        <div className="VisionM-box-VM1">
          <div className="VisionM-box-header-VM1">
            <div className="VisionM-logo-VM1">
              <img src="https://cdn-icons-png.freepik.com/256/16386/16386926.png" alt="Vision Logo" />
            </div>
            <h2 className="VisionM-subheading-VM1">Vision</h2>
          </div>
          <p className="VisionM-text-VM1">
            To emerge as the Most Trusted Recruitment Service Provider in the Industry, anchored on the Values of Growth, Professionalism, and Transparency & Accountability...
          </p>
        </div>

        {/* Mission Box */}
        <div className="VisionM-box-VM1">
          <div className="VisionM-box-header-VM1">
            <div className="VisionM-logo-VM1">
              <img src="https://t4.ftcdn.net/jpg/05/37/39/39/360_F_537393964_1LZXf8pmZAAQBpmH0728GUbt4eUd4ISm.jpg" alt="Mission Logo" />
            </div>
            <h2 className="VisionM-subheading-VM1">Mission</h2>
          </div>
          <p className="VisionM-text-VM1">
            Our mission is to provide our clients and candidates with consistently superior and proficient services which will improve their productivity, profitability, and well-being, on both a corporate and personal level.
          </p>
        </div>
      </div>

      {/* Values Section */}
      <div className="VisionM-values-VM1">
        <h1 className="VisionM-main-heading-VM1">Values</h1>
        <div className="VisionM-values-content-VM1">
          {/* Box 1 */}
          <div className="VisionM-value-box-VM1">
            <div className="VisionM-sub-box-VM1" style={{ backgroundColor: '#f0f8ff', color: '#000' }}>
              <h3 className="VisionM-value-heading-VM1">Innovation</h3>
              <p className="VisionM-value-text-VM1">
                Forward-thinking, planning, and execution to expand our horizons.
              </p>
            </div>
            <div className="VisionM-sub-box-VM1" style={{ backgroundColor: '#ffe4e1', color: '#000' }}>
              <h3 className="VisionM-value-heading-VM1">Integrity</h3>
              <p className="VisionM-value-text-VM1">
                Upholding honesty and strong moral principles in every action.
              </p>
            </div>
            <div className="VisionM-sub-box-VM1" style={{ backgroundColor: '#e6ffe6', color: '#000' }}>
              <h3 className="VisionM-value-heading-VM1">Excellence</h3>
              <p className="VisionM-value-text-VM1">
                Striving for the best in quality, service, and delivery.
              </p>
            </div>
          </div>

          {/* Box 2 */}
          <div className="VisionM-value-box-VM1">
            <div className="VisionM-sub-box-VM1" style={{ backgroundColor: '#e6f7ff', color: '#000' }}>
              <h3 className="VisionM-value-heading-VM1">Respect</h3>
              <p className="VisionM-value-text-VM1">
                Valuing everyone and treating them with dignity.
              </p>
            </div>
            <div className="VisionM-sub-box-VM1" style={{ backgroundColor: '#fff7e6', color: '#000' }}>
              <h3 className="VisionM-value-heading-VM1">Collaboration</h3>
              <p className="VisionM-value-text-VM1">
                Working together to achieve common goals.
              </p>
            </div>
            <div className="VisionM-sub-box-VM1" style={{ backgroundColor: '#f9f9f9', color: '#000' }}>
              <h3 className="VisionM-value-heading-VM1">Accountability</h3>
              <p className="VisionM-value-text-VM1">
                Taking responsibility for actions and decisions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionM;
