import React from 'react';
import './Whyus.css';

const Whyus = () => {
  return (
    <div className="container-us1">
      <h1 className="heading-us1">Why Us</h1>
      <div className="content-container-us1">
        <div className="text-section-us1">
          <p className="paragraph-us1">
            Industries and Corporate houses spend hard-earned financial resources
             and time in recruiting manpower and thus disturb the mainstream of business.
              In spite of hard-core efforts, the result has not been satisfactory. 
              Suitable manpower is either not available, or even if found, 
              has been difficult to retain. Such an elusive situation hampers the growth of the company,
               both structurally and financially.
          </p>
          <button className="load-more-btn-us1">Load More</button>
        </div>
        <div className="image-section-us1">
          <img
            src="https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Why Us"
            className="image-us1"
          />
        </div>
      </div>
    </div>
  );
};

export default Whyus;
