import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './Header2.css';

const Header2 = () => {
  return (
    <header className="header-containerHead25">
      <div className="logo-sectionHead25">
        {/* Navigate to "/" (homepage) when logo is clicked */}
        <Link to="/">
          <img 
            src="https://i.ibb.co/nbBKmWK/Untitled-design-10.png" 
            alt="Logo"
            className="logoHead25" 
          />
        </Link>
      </div>
      <div className="info-sectionHead25">
        <div className="mobile-logoHead25">📞 7019878842</div>
        <div className="emailHead25">✉️ hr@teamstaff.in</div>
      </div>
    </header>
  );
};

export default Header2;
