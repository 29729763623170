import React from 'react';
import './Teams.css';

const Teams = () => {
  return (
    <div className="Team-container-T1">
      <div className="image-section-T1">
        <img 
          src="https://images.pexels.com/photos/6804071/pexels-photo-6804071.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" 
          alt="Team Banner" 
          className="Team-image-T1"
        />
        <h1 className="Team-heading-T1">Team</h1>
      </div>

      <div className="our-team-section-T1">
        <h2 className="our-team-heading-T1">Our Team</h2>
      </div>

      <div className="team-description-section-T1">
        <div className="team-left-image-T1">
          <img 
            src="https://img.freepik.com/premium-vector/diverse-team-collaborates-ideas-global-success_1314935-52734.jpg" 
            alt="Team Image Left" 
            className="team-image-T1"
          />
        </div>
        <div className="team-right-text-T1">
          <p className="team-text-T1">
            Our team of specialists is something, what makes us extremely proud and self-confident. We have assembled a team of professionals that come from a wide range of all related backgrounds, including <strong>underwriting, risk management, as well as business management</strong>, to provide expert knowledge in every line of insurance for every line of industry.
          </p>
        </div>
      </div>

      <div className="professional-staff-T1">
        <p className="professional-staff-text-T1">
          Our professional staff has a broad range of backgrounds, formal education, and streetwise experience that helps us bring a distinctive approach to the tasks of establishing an appropriate positioning strategy, then creating and forwarding continuous, seamless communications strategies and <strong>pro-active awareness campaigns</strong> for our public-company clients.
        </p>
      </div>

      <div className="senior-professionals-T1">
        <p className="senior-professionals-text-T1">
          Our senior-level professionals have been involved in <strong>Client and Job seeker relations, marketing communications, media relations, public relations</strong>, and other related disciplines for a major portion of their careers. Taking full advantage of this diversity of backgrounds and experience, our staff of professionals has established a track record of successfully organizing financial conferences; conducting opinion surveys of fund managers and analysts; setting up and conducting non-deal road trips all over India <strong>generating meaningful coverage in the business and financial media including print, broadcast, wire service, Internet and a range of new media outlets; and consistently delivering significant value to our clients.</strong>
        </p>
      </div>
    </div>
  );
};

export default Teams;
