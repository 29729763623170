import React from 'react';
import './AboutStyle.css';

const About = () => {
  return (
    <div className="about-containerAb1">
      <h1 className="about-headingAb1">About Us</h1>
      <div className="cards-rowAb1">
        {/* Card 1 */}
        <div className="cardAb1">
          <div className="card-logoAb1">
            <img 
              src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcR41YWvOteGK3Dgi_D-ukGGPxOsmwpjc16LGZVDRXUOnuZiYQoR" 
              alt="Corporate Profile Logo" 
              className="logo-imageAb1"
            />
          </div>
          <h2 className="card-headingAb1">Corporate Profile</h2>
          <p className="card-descriptionAb1">
            We provide cutting-edge solutions to redefine businesses.  
          
          </p>
          <a href="Corporate" className="view-detailsAb1">View Details</a>
        </div>

        {/* Card 2 */}
        <div className="cardAb1">
          <div className="card-logoAb1">
            <img 
              src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSkaI3A1ZpNTVSm-9vhPjscgYTNIFQPX18CN_8H7RIqK2s6A1YM" 
              alt="Our Team Logo" 
              className="logo-imageAb1"
            />
          </div>
          <h2 className="card-headingAb1">Our Team</h2>
          <p className="card-descriptionAb1">
            Our team comprises industry experts committed to excellence.  
            
          </p>
          <a href="/Teams" className="view-detailsAb1">View Details</a>
        </div>

        {/* Card 3 */}
        <div className="cardAb1">
          <div className="card-logoAb1">
            <img 
              src="https://citrusgroup.org/wp-content/uploads/2020/09/Artboard-1.png" 
              alt="Vision and Mission Logo" 
              className="logo-imageAb1"
            />
          </div>
          <h2 className="card-headingAb1">Vision and Mission</h2>
          <p className="card-descriptionAb1">
            We envision a future powered by technology and innovation.  
          
          </p>
          <a href="/VisionM" className="view-detailsAb1">View Details</a>
        </div>
      </div>
    </div>
  );
};

export default About;
