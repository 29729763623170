import React from 'react';
import './WalkingIV.css'; // Assuming you have a separate CSS file for styling.

const WalkingIV = () => {
  return (
    <div className="walking1-container">

      {/* Image Section with Text on Top */}
      <div className="walking1-img-section">
        <img src="https://images.pexels.com/photos/4344860/pexels-photo-4344860.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="Walking Interviews" className="walking1-img" />
        <div className="walking1-overlay">
          <h2 className="walking1-text-overlay">Walking Interviews</h2>
        </div>
      </div>

      {/* Walking Interviews Text */}
      <div className="walking1-text-section">
        <h3 className="walking1-text-left">Walking interviews: Why?</h3>
      </div>

      {/* Skills List Section */}
      <div className="walking1-skills-container">
        <div className="walking1-skills-left">
          <ul>
            <li>• Assertiveness</li>
            <li>• Communication Skills</li>
            <li>• Conflict resolution</li>
            <li>• Conversation Skills</li>
            <li>• Emotional Intelligence</li>
          </ul>
        </div>
        <div className="walking1-skills-middle">
          <ul>
            <li>• Emotional Intelligence</li>
            <li>• Interview Skills</li>
            <li>• Leadership Skills</li>
            <li>• Negotiation skills</li>
            <li>• Customer Service</li>
            <li>• Creativity and innovation</li>
          </ul>
        </div>
        <div className="walking1-skills-right">
          <ul>
            <li>• Personality Development</li>
            <li>• Stress Management</li>
            <li>• Team building</li>
            <li>• Time Management</li>
            <li>• Corporate Etiquettes</li>
          </ul>
        </div>
      </div>

      {/* Systematic Hiring Process Section */}
      <section className="walking1-hiring-process">
        <h2 className="walking1-process-heading">Systematic Hiring Process</h2>
        <div className="walking1-process-steps">
          <div className="walking1-process-step">
            <div className="walking1-box walking1-box-analysis">
              <p>Analysis</p>
            </div>
            <div className="walking1-box walking1-box-customize">
              <p>Customize</p>
            </div>
            <div className="walking1-box walking1-box-develop">
              <p>Develop</p>
            </div>
            <div className="walking1-box walking1-box-implement">
              <p>Implement</p>
            </div>
            <div className="walking1-box walking1-box-deliver">
              <p>Deliver</p>
            </div>
          </div>

          {/* Hiring Process Streamlined */}
          <div className="walking1-process-description">
            <h3>Hiring Process Streamlined in Advance</h3>
            <table className="walking1-process-table">
              <tbody>
                <tr><td>Candidates walking through Newspaper, College / Institutes, Social Media</td></tr>
                <tr className="walking1-bg-color" style={{color:"#fff"}}><td>Promotion, Teamstaff technologies database & references</td></tr>
                <tr><td>Teamstaff technologies team is presented venue to manage the walking</td></tr>
                <tr className="walking1-bg-color" style={{color:"#fff"}}><td>Flow of candidates</td></tr>
                <tr><td>On site filtration & screening of candidates</td></tr>
                <tr className="walking1-bg-color" style={{color:"#fff"}}><td>First interview by Teamstaff technologies team</td></tr>
                <tr><td>Post Selection / Offer, remaining shortlisted candidates database is weekly updated by us</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WalkingIV;
