import React from 'react';
import './CorporateT.css';

const CorporateT = () => {
  return (
    <div className="corporate-container-CT2">
      {/* Image with heading */}
      <div className="corporate-banner-CT2">
        <img
          src="https://images.pexels.com/photos/8761523/pexels-photo-8761523.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="Corporate Training"
          className="banner-img-CT2"
        />
        <h1 className="banner-heading-CT2">Corporate Training</h1>
      </div>

      {/* Left side heading */}
      <div className="corporate-main-CT2">
        <h2 className="main-heading-CT2">
          <span className="text-black-CT2">Corporate</span>{' '}
          <span className="text-blue-CT2">Training</span>
        </h2>
        <p className="description-CT2">
          Our corporate training provides you the flexibility to customize
          training as per your firm’s need. We can support your firm with more
          than 100 hundreds of technologies and soft skill training. We can be
          your key training partner in providing training on the latest
          technologies, personal development, and developing a competitive
          advantage to stay ahead of the competition.
        </p>
        <p className="description-CT2">
          Teamstaff’s corporate training brings to you an unparalleled, highly
          interactive, instructor-led, face-to-face training experience in our
          classroom training at our State-of-the-Art training centers.
        </p>
        <p className="description-CT2">
          Our instructors are among the world’s premier IT and management
          authorities. They are highly sought-after industry experts who have
          worked in a variety of organization types and sizes worldwide, from
          leading-edge start-ups and mid-size enterprises to Fortune 500
          companies.
        </p>
      </div>

      {/* Benefits section */}
      <h3 className="benefits-heading-CT2">Benefits of Corporate Training</h3>
      <div className="benefits-container-CT2">
        <div className="benefits-box-CT2">
          <div className="benefit-item-CT2">Highly customizable training to meet your requirement</div>
          <div className="benefit-item-blue-CT2">You can pick your own instructor from our vast trainer pool</div>
          <div className="benefit-item-CT2">Best instructors in the industry with vast experience</div>
          <div className="benefit-item-blue-CT2">Real-life examples-based training</div>
          <div className="benefit-item-CT2">Interact face-to-face or through Live virtual classroom</div>
        </div>
        <div className="benefits-box-CT2">
          <div className="benefit-item-CT2">Engage with topical, results-oriented course content</div>
          <div className="benefit-item-blue-CT2">Use state-of-the-art training infrastructure</div>
          <div className="benefit-item-CT2">Practice what you learn in guided hands-on labs</div>
          <div className="benefit-item-CT2">Nominations option to our open house workshops</div>
        </div>
      </div>

      {/* Why choose Teamstaff section */}
      <div className="why-Teamstaff-CT2">
        <h3>Why choose Teamstaff for your training?</h3>
        <ul>
          <li>Teamstaff is the World’s top IT and professional skill development Training Provider.</li>
          <li>Trusted by Fortune 500 companies for career growth and training.</li>
          <li>4000+ certified trainers conduct training worldwide.</li>
          <li>Excellent track record of 99% pass rate in the first attempt.</li>
        </ul>
      </div>

      {/* Soft Skill Section */}
      <h3 className="soft-skill-heading-CT2">Soft Skills</h3>
      <div className="soft-skill-container-CT2">
        <div className="soft-skill-column-CT2">
          <ul>
            <li>Assertiveness</li>
            <li>Communication Skills</li>
            <li>Conflict resolution</li>
            <li>Conversation Skills</li>
            <li>Corporate Etiquettes</li>
            <li>Creativity and innovation</li>
            <li>Customer Service</li>
            <li>Emotional Intelligence</li>
          </ul>
        </div>
        <div className="soft-skill-column-CT2">
          <ul>
            <li>Emotional Intelligence</li>
            <li>Interview Skills</li>
            <li>Leadership Skills</li>
            <li>Negotiation skills</li>
            <li>Personality Development</li>
            <li>Stress Management</li>
            <li>Team building</li>
            <li>Time Management</li>
          </ul>
        </div>
        <div className="soft-skill-img-CT2">
          <img src="https://st.depositphotos.com/1745098/60825/i/450/depositphotos_608254646-stock-photo-soft-skill-mind-map-written.jpg" alt="Soft Skills" />
        </div>
      </div>
    </div>
  );
};

export default CorporateT;
