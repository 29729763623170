import React from 'react';
import './PrivacyP.css';

const PrivacyP = () => {
  return (
    <div className="privacy-container-prv5">
      {/* Full-width Image Section */}
      <div className="image-section-prv5">
        <img 
          src="https://images.pexels.com/photos/4291/door-green-closed-lock.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" 
          alt="Privacy Policy Banner" 
          className="banner-image-prv5"
        />
      </div>

      {/* Privacy Policy Sections */}
      <div className="content-section-prv5">
        <h1 className="main-heading-prv5">Privacy Policy for Teamstaff Outsourcing</h1>
        <p className="text-prv5">
          At Teamstaff Outsourcing, accessible from Teamstaff Outsourcing, one of our main priorities is the 
          privacy of our visitors. This Privacy Policy document contains types of information 
          that is collected and recorded by Teamstaff Outsourcing and how we use it.
        </p>
        <p className="text-prv5">
          If you have additional questions or require more information about our Privacy Policy, 
          do not hesitate to contact us.
        </p>
        <p className="text-prv5">
          This Privacy Policy applies only to our online activities and is valid for visitors 
          to our website with regards to the information that they shared and/or collect in 
          Teamstaff Outsourcing. This policy is not applicable to any information collected offline 
          or via channels other than this website.
        </p>

        <h2 className="sub-heading-prv5">Consent</h2>
        <p className="text-prv5">
          By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        </p>

        <h2 className="sub-heading-prv5">Information we collect</h2>
        <p className="text-prv5">
          The personal information that you are asked to provide, and the reasons why you are 
          asked to provide it, will be made clear to you at the point we ask you to provide your 
          personal information.
        </p>
        <p className="text-prv5">
          If you contact us directly, we may receive additional information about you such as 
          your name, email address, phone number, the contents of the message and/or attachments 
          you may send us, and any other information you may choose to provide.
        </p>
        <p className="text-prv5">
          When you register for an Account, we may ask for your contact information, including 
          items such as name, company name, address, email address, and telephone number.
        </p>

        <h2 className="sub-heading-prv5">How we use your information</h2>
        <ul className="list-prv5">
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>

        <h2 className="sub-heading-prv5">Log Files</h2>
        <p className="text-prv5">
          Teamstaff Outsourcing follows a standard procedure of using log files. These files log 
          visitors when they visit websites. All hosting companies do this and a part of hosting 
          services' analytics. The information collected by log files include internet protocol 
          (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, 
          referring/exit pages, and possibly the number of clicks. These are not linked to any 
          information that is personally identifiable. The purpose of the information is for 
          analyzing trends, administering the site, tracking users' movement on the website, and 
          gathering demographic information.
        </p>

        <h2 className="sub-heading-prv5">Google DoubleClick DART Cookie</h2>
        <p className="text-prv5">
          Google is one of a third-party vendor on our site. It also uses cookies, known as DART 
          cookies, to serve ads to our site visitors based upon their visit to www.website.com 
          and other sites on the internet. However, visitors may choose to decline the use of 
          DART cookies by visiting the Google ad and content network Privacy Policy at the 
          following URL.
        </p>

        <h2 className="sub-heading-prv5">Advertising Partners Privacy Policies</h2>
        <p className="text-prv5">
          You may consult this list to find the Privacy Policy for each of the advertising 
          partners of Teamstaff Outsourcing.
        </p>

        <h2 className="sub-heading-prv5">CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
        <p className="text-prv5">
          Under the CCPA, among other rights, California consumers have the right to:
        </p>
        <ul className="list-prv5">
          <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
          <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
          <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
        </ul>
        <p className="text-prv5">
          If you make a request, we have one month to respond to you. If you would like to exercise 
          any of these rights, please contact us.
        </p>

        <h2 className="sub-heading-prv5">GDPR Data Protection Rights</h2>
        <p className="text-prv5">
          We would like to make sure you are fully aware of all of your data protection rights. 
          Every user is entitled to the following:
        </p>
        <ul className="list-prv5">
          <li>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
          <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
          <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
          <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
          <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
          <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
        </ul>
        <p className="text-prv5">
          If you make a request, we have one month to respond to you. If you would like to exercise 
          any of these rights, please contact us.
        </p>

        <h2 className="sub-heading-prv5">Children's Information</h2>
        <p className="text-prv5">
          Another part of our priority is adding protection for children while using the internet. 
          We encourage parents and guardians to observe, participate in, and/or monitor and guide 
          their online activity.
        </p>

        <h2 className="sub-heading-prv5">Cancellation & Refund Policy</h2>
        <p className="text-prv5">
          Training course registrations will not be confirmed until registration is complete and 
          billing information is received in full. In order to cancel or reschedule a confirmed 
          training please submit an email request to hr@teamstaff.in
        </p>
        <p className="text-prv5">
          Please submit all cancellation requests within 60 calendar days of registration and no 
          later than 30 days prior to the scheduled course date in order to receive a full refund 
          of paid registration fees.
        </p>
        <p className="text-prv5">
          No shows and cancellations not made within the specified cancellation period will incur 
          the full cost of registration.
        </p>

        <h2 className="sub-heading-prv5">Cancellation by Teamstaff</h2>
        <p className="text-prv5">
          Teamstaff Outsourcing reserves the right to cancel any training course due to insufficient 
          enrollment at least 30 calendar days in advance of the scheduled course date. Notice 
          will be provided with the option to reschedule for a future course date or to receive a 
          full refund of registration fees.
        </p>
        <p className="text-prv5">
          Teamstaff Outsourcing is not responsible for any expenses incurred by the customer if a 
          training course is cancelled.
        </p>
        <p className="text-prv5">
          If a training course is cancelled due to any unforeseen circumstances such as weather or 
          natural disaster, the customer is entitled to reschedule for a future training course.
        </p>
        <p className="text-prv5">
          Training materials will be updated if found outdated , Provided "loose-sheet handed "! Hard 
          copy won’t be issued, Course Curriculum may have updated upon future updates (as per govt norms).
        </p>
        <p className="text-prv5">
          Thank you for choosing Teamstaff Outsourcing. We are committed to providing the best services 
          and ensuring your satisfaction.
        </p>
      </div>
    </div>
  );
};

export default PrivacyP;
