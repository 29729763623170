import React from 'react'
import{BrowserRouter,Routes,Route} from "react-router-dom";
import Careers from './Component/CAREERS/Careers';
// import Contactus from './Component/Contactus/Contactus';
import Header from './Component/Header';
import Homepage from './Component/Homepage';
import Footer from './Component/Footer';
import About from './Component/About';



import Contactus from './Component/Contactus/Contactus';
import HomeImg from './Component/HomeImg';
import Services from './Component/Services';
import Corporate from './Component/Corporate';
import Teams from './Component/Teams';
import VisionM from './Component/VisionM';
import Whyus from './Component/Whyus';
import TrainingDev from './Component/Sub-Services/TrainingDev';
import CorporateT from './Component/Sub-Services/CorporateT';
import WalkingIV from './Component/Sub-Services/WalkingIV';
import RecruitmentP from './Component/Sub-Services/RecruitmentP';
import Resumepost from './Component/Resumepost';
import DomainE from './Component/DomainE';
import PrivacyP from './Component/PrivacyP';
import Header2 from './Component/Header2';






const App = () => {
  return (
    <div>
     <BrowserRouter>
     <Header2/>
        <Header/>
        <Routes>
         
          <Route path='/' exact element={<Homepage/>}></Route>
          <Route path='/HomeImg' exact element={<HomeImg/>}></Route>
          <Route path='/Services' exact element={<Services/>}></Route>
          <Route path='/TrainingDev' exact element={<TrainingDev/>}></Route>
          <Route path='/CorporateT' exact element={<CorporateT/>}></Route>
          <Route path='/WalkingIV' exact element={<WalkingIV/>}></Route>
          <Route path='/RecruitmentP' exact element={<RecruitmentP/>}></Route>


          <Route path='/Resumepost' exact element={<Resumepost/>}></Route>



          <Route path='/Whyus' exact element={<Whyus/>}></Route>
          <Route path='/DomainE' exact element={<DomainE/>}></Route>



          <Route path='/Header' exact element={<Header/>}></Route>
          <Route path='/Header2' exact element={<Header2/>}></Route>

          <Route path='/Footer' exact element={<Footer/>}></Route>
       


          <Route path='/About' exact element={<About/>}></Route>
          
          <Route path='/Corporate' exact element={<Corporate/>}></Route>
          <Route path='/Teams' exact element={<Teams/>}></Route>
          <Route path='/VisionM' exact element={<VisionM/>}></Route>




 


          <Route path='/Careers' exact element={<Careers/>}></Route>
          <Route path='/Contactus' exact element={<Contactus/>}></Route>
          <Route path='/PrivacyP' exact element={<PrivacyP/>}></Route>




   
       
          

          
     
       
          
       </Routes>
        <Footer/>
      </BrowserRouter>
      
    </div>
  )
}

export default App




