import React from "react";
import "./FooterStyle.css";

const Footer = () => {
  return (
    <div className="footer-containerFTR2">
      <div className="footer-sectionFTR2 sitemapFTR2">
        <h2 className="footer-headingFTR2">Sitemap</h2>
        <ul className="footer-linksFTR2">
          <li><a href="/" className="footer-linkFTR2">Home</a></li>
          <li><a href="/Corporate" className="footer-linkFTR2">Corporate Profile</a></li>
          <li><a href="/Teams" className="footer-linkFTR2">Our Team</a></li>
          <li><a href="/VisionM" className="footer-linkFTR2">Vision & Mission</a></li>
          <li><a href="/Resumepost" className="footer-linkFTR2">Post Resume</a></li>
          <li><a href="/Contactus" className="footer-linkFTR2">Contact</a></li>
          <li><a href="/PrivacyP" className="footer-linkFTR2">Privacy Policy</a></li>
        </ul>
      </div>

      <div className="footer-sectionFTR2 servicesFTR2">
        <h2 className="footer-headingFTR2">Services</h2>
        <ul className="footer-linksFTR2">
          <li><a href="/TrainingDev" className="footer-linkFTR2">Training & Development</a></li>
          <li><a href="/CorporateT" className="footer-linkFTR2">Corporate Training</a></li>
          <li><a href="/WalkingIV" className="footer-linkFTR2">Walking Interviews</a></li>
          <li><a href="/RecruitmentP" className="footer-linkFTR2">Recruitment Process</a></li>
        </ul>
      </div>

      <div className="footer-sectionFTR2 locationFTR2">
        <h2 className="footer-headingFTR2">Location</h2>
        <address className="footer-addressFTR2">
          <p># 12 Second Floor 3rd Cross</p>
          <p>Patel Narayana,</p>
          <p>Venkat Reddy Layout,</p>
          <p> 6th Block, Koramangala, </p>
          <p>Bengaluru, Karnataka 560095</p>
          <p>7019878842</p>
          <p><a href="mailto:hr@Teamstafftech.com" className="footer-emailFTR2">hr@teamstaff.in</a></p>
        </address>
      </div>

      <div className="footer-bottomFTR2">
        <p>
          Copyright © 2022 All Rights Reserved by{" "}
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-bottom-linkFTR2"
          >
            Teamstaff Outsourcing
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
