import React from 'react';
import './RecruitmentP.css'; // Importing the CSS file for styles

const RecruitmentP = () => {
  return (
    <div className="req55-container">
      {/* Image Section with Text on Top */}
      <div className="req55-img-section">
        <img
          src="https://images.pexels.com/photos/8636626/pexels-photo-8636626.jpeg?auto=compress&cs=tinysrgb&w=600"
          alt="Recruitment Process"
          className="req55-img"
        />
        <div className="req55-overlay">
          <h2 className="req55-text-overlay">Recruitment Process</h2>
        </div>
      </div>

      {/* Recruitment Process Heading */}
      <div className="req55-heading-section">
        <h3 className="req55-heading-left">Recruitment Process</h3>
      </div>

      {/* Description Text */}
      <div className="req55-description">
        <p>
          We are engaged in offering high-standard Human Resource Services to our clients. Our services are widely demanded by various organizations for the ability to suitable employees for different positions. These services encompass Recruitment and Selection Services, Contractual Staffing, Payroll Management, and Legal Consulting. Moreover, these services are provided by proficient HR professionals, who are experts in this domain. We take great pleasure in introducing and offering Teamstaff technologies- Staffing solutions.
        </p>
      </div>

      {/* Image Section at the Bottom */}
      <div className="req55-bottom-img-section">
        <img
          src="https://images.pexels.com/photos/4344878/pexels-photo-4344878.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="HR Services"
          className="req55-bottom-img"
        />
      </div>
    </div>
  );
};

export default RecruitmentP;
