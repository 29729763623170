import React from 'react'
import About from './About'
import HomeImg from './HomeImg'
import Services from './Services'
import Whyus from './Whyus'
import DomainE from './DomainE'


const Homepage = () => {
  return (
    <div>
   
<HomeImg/>
  <About/>
  <DomainE/>
  <Services/>
  <Whyus/>
   
    </div>
  )
}

export default Homepage
