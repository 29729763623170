import React, { useState } from 'react';
import './HeaderStyle.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false); // Close the sidebar
  };

  return (
    <header className={`header-containerDevH ${menuOpen ? 'active' : ''}`}>
      {/* Sidebar Toggle Icon */}
      <div className="sidebar-iconDevH" onClick={toggleMenu}>
        &#9776; {/* Hamburger Icon */}
      </div>

      <nav className={`navDevH ${menuOpen ? 'open' : ''}`}>
        {/* Close Icon for Sidebar */}
        {menuOpen && (
          <div className="cancel-iconDevH" onClick={closeMenu}>
            &#10005; {/* Cancel Icon */}
          </div>
        )}

        <ul className="nav-listDevH">
          <li className="nav-itemDevH">
            <a href="/" className="nav-linkDevH">HOME</a>
          </li>
          <li className="nav-itemDevH">
            <a className="nav-linkDevH">ABOUT US</a>
            <ul className="dropdownDevH">
              <li className="dropdown-itemDevH"><a href="/Corporate">Corporate Profile</a></li>
              <li className="dropdown-itemDevH"><a href="/Teams">Our Team</a></li>
              <li className="dropdown-itemDevH"><a href="/VisionM">Vision and Mission</a></li>
            </ul>
          </li>
          <li className="nav-itemDevH">
            <a className="nav-linkDevH">SERVICES</a>
            <ul className="dropdownDevH">
              <li className="dropdown-itemDevH"><a href="/TrainingDev">Training Development</a></li>
              <li className="dropdown-itemDevH"><a href="/CorporateT">Corporate Training</a></li>
              <li className="dropdown-itemDevH"><a href="/WalkingIV">Walking Interviews</a></li>
              <li className="dropdown-itemDevH"><a href="/RecruitmentP">Recruitment Process</a></li>
            </ul>
          </li>
          <li className="nav-itemDevH">
            <a className="nav-linkDevH">CANDIDATES</a>
            <ul className="dropdownDevH">
              <li className="dropdown-itemDevH"><a href="/Resumepost">Post Resume</a></li>
            </ul>
          </li>
          <li className="nav-itemDevH">
            <a href="/Contactus" className="nav-linkDevH">CONTACT US</a>
          </li>
        </ul>
      </nav>

      <div className="register-buttonDevH">
        <a href="/Resumepost" className="btnDevH">Register</a>
      </div>
    </header>
  );
};

export default Header;
