import React, { useState } from 'react';
import './ContactusStyle.css'; // Import the CSS file for styles

const Contactus = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = 'Full Name is required';
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
    if (!formData.subject) newErrors.subject = 'Subject is required';
    if (!formData.message) newErrors.message = 'Message is required';
    else if (formData.message.length > 300) newErrors.message = 'Message should not exceed 300 words';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setMessage('Data submitted successfully!');
      // Reset form
      setFormData({
        fullName: '',
        email: '',
        subject: '',
        message: '',
      });
    }
  };

  return (
    <div>
      {/* Image Section */}
      <div className="contactus-img-section">
        <img
          src="https://images.pexels.com/photos/599982/pexels-photo-599982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt="Contact Us"
          className="contactus-img"
        />
        <div className="contactus-overlay">
          <h2 className="contactus-text-overlay">Contact Us</h2>
        </div>
      </div>

      {/* Form and Info Section */}
      <div className="contactus-main-section">
        <div className="contactus-form-section">
          <h3 className="contactus-form-heading">Contact Us</h3>
          <form className="contactus-form" onSubmit={handleSubmit}>
            <div className="contactus-row">
              <div className="contactus-form-group">
                <label htmlFor="fullName" className="contactus-label">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  className="contactus-input"
                  value={formData.fullName}
                  onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                  placeholder="Enter your full name"
                />
                {errors.fullName && <span className="contactus-error">{errors.fullName}</span>}
              </div>
              <div className="contactus-form-group">
                <label htmlFor="email" className="contactus-label">Email</label>
                <input
                  type="email"
                  id="email"
                  className="contactus-input"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  placeholder="Enter your email"
                />
                {errors.email && <span className="contactus-error">{errors.email}</span>}
              </div>
            </div>
            <div className="contactus-row">
              <div className="contactus-form-group">
                <label htmlFor="subject" className="contactus-label">Subject</label>
                <input
                  type="text"
                  id="subject"
                  className="contactus-input"
                  value={formData.subject}
                  onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                  placeholder="Enter the subject"
                />
                {errors.subject && <span className="contactus-error">{errors.subject}</span>}
              </div>
              <div className="contactus-form-group">
                <label htmlFor="message" className="contactus-label">Message</label>
                <textarea
                  id="message"
                  className="contactus-textarea"
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  placeholder="Enter your message"
                />
                {errors.message && <span className="contactus-error">{errors.message}</span>}
              </div>
            </div>
            <div className="contactus-form-group">
              <button type="submit" className="contactus-submit-btn">Submit</button>
            </div>
            {message && <div className="contactus-success-message">{message}</div>}
          </form>
        </div>

        <div className="contactus-info-section">
          <h3 className="contactus-info-heading">Contact Info</h3>
          <div className="contactus-address-section">
            <h4 className="contactus-address-heading">Address</h4>
            <p className="contactus-address-text">
              Teamstaff Outsourcing<br />
              Patel Narayana, 12 Second Floor 3rd Cross, Venkat Reddy Layout, 6th Block, Koramangala, Bengaluru, Karnataka 560095
            </p>
          </div>
          <div className="contactus-phone-section">
            <h4 className="contactus-phone-heading">Phone</h4>
            <p className="contactus-phone-text">7019878842</p>
          </div>
          <div className="contactus-email-section">
            <h4 className="contactus-email-heading">Email</h4>
            <p className="contactus-email-text">hr@teamstaff.in</p>
          </div>
          <div className="contactus-more-info-section">
            <h4 className="contactus-more-info-heading">More Info</h4>
            <p className="contactus-more-info-text">
              We are a successfully operating Multi Placement Organization, with enough staff strength of Recruitment executives, having unlimited database of candidates from various background in “PUNE”.
            </p>
          </div>
          <div className="contactus-load-more-btn">
            <button className="contactus-btn">Load More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
