import React, { useState } from 'react';
import './DomainE.css';

const DomainE = () => {
  const [openCategory, setOpenCategory] = useState('');

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? '' : category);
  };

  const domainData = [
    {
      category: "IT & IT's",
      items: ["Development", "Support", "Analytics", "Operations"],
    },
    {
      category: "Telecom",
      items: ["Technical" , "Networking"],
    },
    {
      category: "Banking & FMCG",
      items: ["Customer Support" , "Data Process" , "Operations" , "Sales and Marketing" , "Back office"],
    },
    {
      category: "BPO & KPO",
      items: ["Inbound" , "Outbound" , "Customer Service Associate" , "Accounting", "Process management" , "Operations"],
    },
    {
      category: "Corporate Training Program",
      items: [
        "Inbound",
        "Outbound",
        "Customer Service Associate",
        "Accounting",
        "Process management",
        "Operations",
      ],
    },
    {
      category: "Pharmaceutical/ Pharma plant",
      items: ["R & D" , "Regulatory" , "Production" , 
        "Formulation Developement" , "Contract Research Organisation (CRO)" 
        ,"Pharmaceutical Machinery" , "Medical Advisors" , "Biotech" , "Supply Chain" , "Pharmaceutical Project"],
    },
    {
      category: "Manufacturing",
      items: ["PPC (Production, Planning & Control)" , "R & D" , "Material Management" ,
         "Local & Global Sourcing" , "Inventroy & Material Management" , "Maintenance & Services" ,"QC & QA"],
    },
    {
      category: "e-Government Project",
      items: [],
    },
  ];

  // Split categories into left and right columns
  const leftColumnData = domainData.slice(0, 4); // First 4 categories
  const rightColumnData = domainData.slice(4);  // Remaining categories

  return (
    <div className="domain-container">
      <h1 className="domain-heading">Domain Expertise</h1>
      <div className="domain-columns">
        {/* Left Column */}
        <div className="domain-column">
          {leftColumnData.map((domain, index) => (
            <div key={index} className="domain-category">
              <div
                className="domain-title"
                onClick={() => toggleCategory(domain.category)}
              >
                <span className="domain-icon">
                  {openCategory === domain.category ? '▲' : '▼'}
                </span>
                {domain.category}
              </div>
              {openCategory === domain.category && (
                <ul className="domain-items">
                  {domain.items.map((item, idx) => (
                    <li key={idx} className="domain-item">
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>

        {/* Right Column */}
        <div className="domain-column">
          {rightColumnData.map((domain, index) => (
            <div key={index} className="domain-category">
              <div
                className="domain-title"
                onClick={() => toggleCategory(domain.category)}
              >
                <span className="domain-icon">
                  {openCategory === domain.category ? '▲' : '▼'}
                </span>
                {domain.category}
              </div>
              {openCategory === domain.category && (
                <ul className="domain-items">
                  {domain.items.map((item, idx) => (
                    <li key={idx} className="domain-item">
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DomainE;
