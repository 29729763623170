import React from 'react';
import './TrainingDev.css';

const TrainingDev = () => {
  return (
    <div className="containerTr1">
      {/* Image Section */}
      <section className="image-sectionTr1">
        <img src="https://images.pexels.com/photos/6981024/pexels-photo-6981024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="Training Program" className="imageTr1" />
      </section>
      <h1 className="Training-heading-crt1">Training and development</h1>
      {/* Objective Section */}
      <section className="objective-sectionTr1">
        <div className="left-contentTr1">
          <h1 className="headingTr1">Objective</h1>
          <p className="textTr1">
            The objective of Training Program is to provide a comprehensive learning platform to students wherein they
            can enhance their employability skills and become job-ready along with real corporate exposure.
          </p>
          <p className="textTr1">
            • It helps engineering students to enhance their Practical Knowledge about the subject.
            <br />
            • It nurtures students' Employability Skills, helps in reducing the Skill Gap & makes students Job Ready.
            <br />
            • It provides real Corporate Exposure & increases the future Job Prospects.
          </p>
        </div>
        <div className="left-contentTr1">
          <h2 className="subheadingTr1">Training Program (ELTP)</h2>
          <p className="textTr1">
            The Entry Level Training Program acts as a vital support to companies, as it shoulders the responsibility
            of grooming new recruits into project-ready professionals for immediate deployment on projects.
          </p>
          <p className="textTr1">
            Fresher (Entry Level) Training Program (ELTP) is designed by Subject Matter Experts (SMEs) from Teamstaff in
            collaboration with Technical Experts of the client company. The assignments and complexities of the
            training are decided based on project-specific requirements and inputs from the client.
          </p>
          <p className="highlighted-textTr1">This program ensures that the participating candidates become readily billable & deployable resources.</p>
        </div>
      </section>

      {/* Table Section */}
      <section className="table-sectionTr1">
        
        <div className="table-containerTr1">
          <table className="info-tableTr1">
            <tbody>
              <tr>
                <th className="table-headingTr1">Participant's Profile</th>
                <td className="table-dataTr1">Fresher: Graduate or post-graduate (CS, IT, ENTC, and other branches)</td>
              </tr>
              <tr>
                <th className="table-headingTr1">Typical Duration</th>
                <td className="table-dataTr1">30 to 45 days</td>
              </tr>
              <tr>
                <th className="table-headingTr1">Expected Outcomes</th>
                <td className="table-dataTr1">
                  <p>Sound fundamentals of programming/testing/System Administration</p>
                  <p>Knowledge of basic set of tools and languages</p>
                  <p>Knowledge of applying technology to solve a problem based on case studies</p>
                </td>
              </tr>
              <tr>
                <th className="table-headingTr1">Course Contents</th>
                <td className="table-dataTr1">
                  <p>Generally designed by the joint team of Subject Matter Experts</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      {/* List of Courses Section */}
      <section className="courses-sectionTr1">
        <h2 className="courses-headingTr1">List of Courses</h2>
        <p className="textTr1">
          Following are the modules covered as part of this type of training. All of these modules can be customized to
          the extent desired in the context of project requirements.
        </p>
        <div className="table-containerTr1">
          <table className="info-tableTr1">
            <tbody>
              <tr>
                <th className="table-headingTr1">Fundamentals</th>
                <td className="table-dataTr1">C programming, SQL, DBMS concepts, Unix basics.</td>
              </tr>
              <tr>
                <th className="table-headingTr1">Java</th>
                <td className="table-dataTr1">Java, JDBC, Servlet/JSP, EJB</td>
              </tr>
              <tr>
                <th className="table-headingTr1">.NET/ PHP</th>
                <td className="table-dataTr1">
                  <p>C#, ADO.NET, ASP.NET</p>
                  <p>PHP basics, Framework, Codeignitor</p>
                  
                </td>
              </tr>
              <tr>
                <th className="table-headingTr1">Software testing</th>
                <td className="table-dataTr1">
                  <p>	Software testing concepts</p>
                </td>
              </tr>

              <tr>
  <th className="table-headingTr1">Python</th>
  <td className="table-dataTr1">
    <p>Python basics</p>
    <p>Advanced Python concepts</p>
    <p>Python frameworks like Django and Flask</p>
  </td>
</tr>
<tr>
  <th className="table-headingTr1">Front End</th>
  <td className="table-dataTr1">
    <p>HTML, CSS, JavaScript</p>
    <p>React.js, Angular</p>
    <p>Responsive design and frameworks (Bootstrap, Tailwind CSS)</p>
  </td>
</tr>
<tr>
  <th className="table-headingTr1">Salesforce Developer and Admin</th>
  <td className="table-dataTr1">
    <p>Salesforce administration</p>
    <p>Salesforce development using Apex</p>
    <p>Lightning framework</p>
    <p>Integration with third-party services</p>
  </td>
</tr>
<tr>
  <th className="table-headingTr1">ServiceNow</th>
  <td className="table-dataTr1">
    <p>IT service management (ITSM)</p>
    <p>ServiceNow scripting</p>
    <p>Workflow automation</p>
    <p>ServiceNow integrations</p>
  </td>
</tr>
<tr>
  <th className="table-headingTr1">SAP MM</th>
  <td className="table-dataTr1">
    <p>Material management concepts</p>
    <p>Procurement and inventory management</p>
    <p>Integration with other SAP modules</p>
  </td>
</tr>
<tr>
  <th className="table-headingTr1">SAP FICO</th>
  <td className="table-dataTr1">
    <p>Financial accounting and controlling</p>
    <p>General ledger, accounts receivable, and accounts payable</p>
    <p>Integration with SAP modules like MM and SD</p>
  </td>
</tr>
<tr>
  <th className="table-headingTr1">SAP SD</th>
  <td className="table-dataTr1">
    <p>Sales and distribution concepts</p>
    <p>Order management and billing</p>
    <p>Integration with SAP modules like MM and FICO</p>
  </td>
</tr>
<tr>
  <th className="table-headingTr1">Python Full Stack Developer</th>
  <td className="table-dataTr1">
    <p>Front-end: HTML, CSS, JavaScript, React.js</p>
    <p>Back-end: Python with Django/Flask</p>
    <p>Database: SQL, MongoDB</p>
  </td>
</tr>
<tr>
  <th className="table-headingTr1">Java Full Stack Developer</th>
  <td className="table-dataTr1">
    <p>Front-end: HTML, CSS, JavaScript, Angular</p>
    <p>Back-end: Java with Spring/Spring Boot</p>
    <p>Database: MySQL, PostgreSQL</p>
  </td>
</tr>


            </tbody>
          </table>
        </div>
      </section>

      {/* Advantages Section */}
      <section className="advantages-sectionTr1">
        <h2 className="headingTr1">Advantage: - Our training results are</h2>
        <div className="advantages-rowTr1">
          <div className="advantage-itemTr1 bg1Tr1">Enhanced Knowledge</div>
          <div className="advantage-itemTr1 bg2Tr1">Efficiency</div>
          <div className="advantage-itemTr1 bg3Tr1">Productivity</div>
        </div>
      </section>

      {/* Final Section */}
      <section className="final-sectionTr1">
        <h2 className="headingTr1">We bring the best training convenience and results with</h2>
        <p className="textTr1">
          • Customization options – Modifying existing courses, blending delivery methods, custom-designing courseware,
          coaching and mentoring on technologies and tools, consulting for new technology assimilation to address
          client-specific situation and challenges
        </p>
        <p className="textTr1">
          • Flexible approach – Client site offerings for all courses
        </p>
        <p className="textTr1">
          • Wide range of offerings – Information Technology, Project Management, General Management and Management
          development programs
        </p>
        <p className="textTr1">
          • Quality instructors – Certified technical instructors, with real-world experience working in
          multi-technology, multi-vendor, multi-network environments
        </p>
        <p className="textTr1">
          • Comprehensive Course/ study material – Easy to understand and comprehend study material and practical
          assignments
        </p>
      </section>
    </div>
  );
};

export default TrainingDev;
