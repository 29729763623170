import React, { useState, useEffect } from "react";
import "./HomeImg.css";

const HomeImg = () => {
  const images = [
    "https://images.pexels.com/photos/1714208/pexels-photo-1714208.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "https://images.pexels.com/photos/20694725/pexels-photo-20694725/free-photo-of-a-modern-home-desk-setup-with-a-computer-and-a-tablet.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    "https://images.pexels.com/photos/1181354/pexels-photo-1181354.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(true);
  const [formData, setFormData] = useState({ name: "", mobile: "" });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setShowPopup(false);
  };

  return (
    <div className="home-containerHimg1">
      <div className="image-sectionHimg1">
        <img
          src={images[currentIndex]}
          alt="Home Page"
          className="home-imageHimg1"
        />
      </div>

      {showPopup && (
        <div className="popupHimg1">
          <div className="popup-contentHimg1">
            <h2 className="popup-headingHimg1">
              Teamstaff Offering Training & Job Services
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="form-groupHimg1">
                <label htmlFor="name">Candidate Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-groupHimg1">
                <label htmlFor="mobile">Mobile Number</label>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <button type="submit" className="submit-buttonHimg1">
                Submit
              </button>
              <button
                type="button"
                className="close-buttonHimg1"
                onClick={handleClosePopup}
              >
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeImg;
